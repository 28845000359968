exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-ev-js": () => import("./../../../src/pages/ev.js" /* webpackChunkName: "component---src-pages-ev-js" */),
  "component---src-pages-fleet-management-malaysia-js": () => import("./../../../src/pages/fleet-management-malaysia.js" /* webpackChunkName: "component---src-pages-fleet-management-malaysia-js" */),
  "component---src-pages-fuel-js": () => import("./../../../src/pages/fuel.js" /* webpackChunkName: "component---src-pages-fuel-js" */),
  "component---src-pages-gps-tracker-apad-permit-renewal-js": () => import("./../../../src/pages/gps-tracker-apad-permit-renewal.js" /* webpackChunkName: "component---src-pages-gps-tracker-apad-permit-renewal-js" */),
  "component---src-pages-gps-tracker-malaysia-js": () => import("./../../../src/pages/gps-tracker-malaysia.js" /* webpackChunkName: "component---src-pages-gps-tracker-malaysia-js" */),
  "component---src-pages-gpsfleet-management-system-js": () => import("./../../../src/pages/gpsfleet-management-system.js" /* webpackChunkName: "component---src-pages-gpsfleet-management-system-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-v-1-js": () => import("./../../../src/pages/index-v1.js" /* webpackChunkName: "component---src-pages-index-v-1-js" */),
  "component---src-pages-industrial-iot-js": () => import("./../../../src/pages/industrial-iot.js" /* webpackChunkName: "component---src-pages-industrial-iot-js" */),
  "component---src-pages-infrastructure-js": () => import("./../../../src/pages/infrastructure.js" /* webpackChunkName: "component---src-pages-infrastructure-js" */),
  "component---src-pages-leadership-js": () => import("./../../../src/pages/leadership.js" /* webpackChunkName: "component---src-pages-leadership-js" */),
  "component---src-pages-mdvr-js": () => import("./../../../src/pages/mdvr.js" /* webpackChunkName: "component---src-pages-mdvr-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-quality-health-safety-and-environment-policy-js": () => import("./../../../src/pages/quality-health-safety-and-environment-policy.js" /* webpackChunkName: "component---src-pages-quality-health-safety-and-environment-policy-js" */),
  "component---src-pages-returns-and-exchanges-policy-js": () => import("./../../../src/pages/returns-and-exchanges-policy.js" /* webpackChunkName: "component---src-pages-returns-and-exchanges-policy-js" */),
  "component---src-pages-success-stories-js": () => import("./../../../src/pages/success-stories.js" /* webpackChunkName: "component---src-pages-success-stories-js" */),
  "component---src-pages-support-frequently-asked-questions-js": () => import("./../../../src/pages/support/frequently-asked-questions.js" /* webpackChunkName: "component---src-pages-support-frequently-asked-questions-js" */),
  "component---src-pages-support-installation-guidelines-js": () => import("./../../../src/pages/support/installation-guidelines.js" /* webpackChunkName: "component---src-pages-support-installation-guidelines-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-warranty-statement-js": () => import("./../../../src/pages/warranty-statement.js" /* webpackChunkName: "component---src-pages-warranty-statement-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-career-post-js": () => import("./../../../src/templates/career-post.js" /* webpackChunkName: "component---src-templates-career-post-js" */),
  "component---src-templates-doc-post-archive-js": () => import("./../../../src/templates/doc-post-archive.js" /* webpackChunkName: "component---src-templates-doc-post-archive-js" */),
  "component---src-templates-doc-post-js": () => import("./../../../src/templates/doc-post.js" /* webpackChunkName: "component---src-templates-doc-post-js" */)
}

